@import url(../../variables.css);
.shows-grid{
    display: grid;
    grid-gap: 30px;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(6,1fr);
    padding-bottom: 36px;
}
.show-grid-list{
    text-align: center;
}
.show-type{
    margin: 20px 10px;
    padding: 10px 20px;
    padding-right: 30px;
    font-size: 1.3rem;
    background-color: var(--body-bg);
    color: white;
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid white;
}
.show-type:focus,
.show-type:hover{
    outline: none;
}
@media (max-width:1320px){
    .shows-grid{

        grid-template-columns:  repeat(5,2fr);
    }
}
@media (max-width:1130px){
    .shows-grid{

        grid-template-columns:  repeat(4,2fr);
    }
}
@media (max-width:845px){
    .shows-grid{
        grid-gap: 10px;
    }
}
@media (max-width:845px){
    .shows-grid{
        grid-template-columns: repeat(3,2fr);
    }
}
@media(max-width:500px){
    .shows-grid{
        grid-template-columns: repeat(2,2fr);
    }
}
@media(max-width:420px){
    .shows-grid{
        grid-template-columns: repeat(1,1fr);
    }
}