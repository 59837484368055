@import url(../../variables.css);

.mt-card {
  width: 100%;
  transition: all 0..2s linear;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 350px;
  border-radius: var(--border-radius);
  position: relative;
}

.mt-card::before{
    content: "";
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    border-radius: var(--border-radius);
    opacity: 0;
}
.mt-card__title{
    padding-top: 1.2rem;
    text-align: center;
}

.mt-card__btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: all 0.2s ease-in;
  background-color: #ff0000;
  box-shadow: rgb(255 0 0) -1px 0px 19px 0px;
  border: none;
  width: 100px;
}

.mt-card:hover .mt-card__btn{
    top:50%;
    transform: translate(-50%, -50%) scale(1);
}
.mt-card:hover::before{
    opacity: 0.7;
}
@media (max-width:500px){
  .mt-card{
    height: 300px;
  }
  .mt-card__btn{
    width: 80px;
    
  }
}
@media (max-width:460px){
  .mt-card{
    height: 300px;
  }
}