.home-menu{
    margin: 3.2rem 2rem;
}
.home-menu__info{
    display:grid;
    grid-template-columns: 7fr 1fr;
}
@media (max-width:500px){
    .home-menu{

        margin: 3rem 1rem;
    }
}
@media (max-width:400px) {
    .home-menu{

        margin: 2rem 0rem;
    }
    .home-menu__heading{
        align-self: center;
        font-size: 1.5rem;
        padding-left: 1.2em;
    }
}