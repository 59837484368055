.brand{
    display: flex;
    align-items: center; 
}
.brand img {
    width: 50px;
    height: 50px;
    padding-right: 10px;
  }
.brand p {
    font-weight: bold;
    font-size: 2rem;
  }
@media (max-width:330px){
  .brand img{
    width: 30px;
    height: 30px;
  }
  .brand p {
    font-weight: bold;
    font-size: 1.3rem;
  }
}