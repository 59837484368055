/* imposting varibales */
@import "./variables.css";

/* imposrting fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');

/* Restting */
*{
    padding: 0;
    margin: 0;
}
body{
    font-family: var(--font-family);
    background-color: var(--body-bg);
    color: var(--txt-color);
    font-weight: 400;
}
a{ 
    color: var(--txt-color) ;   
    text-decoration: none;
}
.btn-outline{
    border-radius: var(--border-radius);
    background-color: var(--body-bg);
    color: var(--txt-color);
    padding: 15px 25px;
    border: 1px solid var(--white) ;
    font-size: 20px;
    cursor: pointer;
    width: 200px;
    height: 50px;
    transition: all .2s linear;
}
.btn-outline:hover{
    transform: scale(1.2);
}
.disable{
    cursor: not-allowed;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: blue;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--main-color);
    border-radius: 10px;
    box-shadow: var(--box-shadow);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--main-color);
  }
  @media (max-width:650px){
    .btn-outline{
      width: 150px;
      height: 50px;
      font-size: 15px;
    }
  }
  
@media (max-width:460px){
  .btn-outline{
    width: 100px;
    font-size: 15px;
    padding: 10px 15px;
  }
}
@media (max-width:370px) {
.btn-outline{
  width:80px;
  font-size: 13px;
  padding: 4px 8px
}
  
}
