
.modal-backdrop{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top:0;
    left: 0;
    background-color: rgba(26, 25, 25, 0.515);
    display: flex;
    justify-content: center;
    opacity: 0;
    visibility: hidden;

}
.modal-content{
    text-align: end;
    background-color: black;
    margin: auto;
    padding: 10px 20px;
    width: 70%;
}
.active{
    opacity: 1;
    visibility: visible;
}
.modal-close{
    margin-bottom: 20px;
}