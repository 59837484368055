.movie-slide__item {
  padding: 9rem 0;
  width: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.movie-slide__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0.5914740896358543) 0%, rgba(0,0,0,1) 74%);}

.movie-details{
    position: relative;
    display: flex;
    padding: 0 10rem;

}
.movie-heading{
    font-size: 7rem;
    font-weight: bold;
}
.movie-desc{
    padding: 2rem 0rem;
    font-size: 1.5rem;
    padding-right: 20px;

}
.movie-poster__img{
    width: 500px;
    border-radius: 50px ;
}

@media (max-width:1458px) {
  .movie-heading{
    font-size: 4.5rem;
  }
  .movie-desc{
    font-size: 1.2rem;
  }

  .movie-poster__img{
    width: 400px;
  }
  .movie-slide__item{
    padding-bottom: 6rem;
  }
}
@media (max-width:1250px){
  .movie-heading{
    font-size: 3.5rem;
  }
  .movie-desc{
    font-size: 1rem;
    
  }

  .movie-poster__img{
    width: 350px;
  }
  .movie-slide__item{
    padding-bottom: 5rem;
  }
}
@media (max-width:1000px){
  .movie-poster{
    display: none;
  }
}
@media (max-width:778px){
  .movie-details{
    padding: 0 3rem;
  }
}
@media (max-width:450px){
  .movie-heading{
    font-size: 2rem;
  }
  .movie-desc{
    font-size: 14px;
    
  }
  .movie-slide__item{
    padding-bottom: 5rem;
  }
}