.iframe-video{
    text-align: center;
    margin-bottom: 1.3rem;
    position: relative;
    overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
  
}
.video-title{
    margin-bottom: 1.3rem;
}
.ifram-wrapper{
    position: relative;
    overflow: hidden;
  width: 100%;
  
}
.iframe-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}