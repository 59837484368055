@import url("../../variables.css");

.navbar {
  /* display: grid;
  grid-template-columns: 3fr 2fr; */
  display: flex;
  padding: 10px 40px;
  position: fixed;
  z-index: 999;
  background-color: transparent;
  width: 100%;
  transition: all 0.3s;
  overflow: hidden;
}

.nav-menu {
  align-self: center;
  width: 100%;
}

/* Menu css */
.nav-menu ul {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 90px;
}
.nav-list {
  list-style: none;
  margin:0 20px;
  padding-bottom: 5px;
  transition: all 0.2s linear;
  font-size: large;
  font-weight: bold;
  position: relative;
}
.nav-list::after,
.nav-list .nav-list__link::after{
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  transition: width 0.5s ease;
  height: 2px;
  background-color: var(--main-color);
}
.nav-list:hover::after,
.nav-list .nav-list__link.selected::after{
    width: 100%;
}
.black-nav{
  background-color: black;
}

.ham-menu{
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
  position: fixed;
  top:3%;
  right: 6%;
  cursor: pointer;
}


@media (max-width:600px){
  .navbar{
    flex-direction: column;
    padding: 0;
  }
  .nav-brand{
    padding: 20px;
  }
  .nav-menu{
    display: none;
    padding-top: 20px;
    background-color: var(--black);
  }
  .nav-menu ul{
    justify-content: flex-start;
    flex-direction: column;
    padding: 5px;
  }
  .nav-list{
    margin-bottom: 10px;
  }
  
}
