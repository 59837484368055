:root {
    --body-bg: #0f0f0f;
    --main-color: #ff0000;
    --txt-color: #fff;
  
    --white: #fff;
    --black: #000;
  
    --box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  
    --header-height: 8rem;
    --header-shrink-height: 5rem;
    --border-radius: 30px;
  
    --mobile-width: 600px;
    --tablet-width: 1024px;
  
    --font-family: "Montserrat", sans-serif;
  }