.show-banner{
  padding: 9rem 0;
  width: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}
.show-banner::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0.5914740896358543) 0%, rgba(0,0,0,1) 74%);}
  
.show-banner-details{
    display: grid;
    grid-template-columns: 3fr 3fr;
    justify-content: center;
    align-items: center;
    position: relative;
}
.show-poster{
    text-align: center;
}
.show-poster-image{
    width: 500px;
    border-radius: 50px ;
}
.show-heading{
    font-size: 5rem;
}
.genres{
    margin:20px 0;
    padding-right: 30%;
    display: flex;
    flex-wrap: wrap;
}
.genre-name{
    padding: 20px;
    margin-right: 20px;
    border: 2px solid white;
    border-radius: 30px;
}
.show-desc{
    width: 50%;
}
.show-rating{
    margin: 20px 0;
    font-size: 1.6rem;
}
.show-rating span{
    padding-left: 10px;
}

.show-cast{
    padding: 40px 20px;
}
.show__heading{
    padding-bottom: 1.5rem;
    padding-left: 1.3rem;
    font-size: 2.3rem;
}
.show-video{
    margin: 0 50px;
}
.show-similar{
    padding-left: 20px;
}
.show-similar h1{
    padding-left: 1.3rem;
    font-size: 2.3rem;
}
@media (max-width:1095px){
    .show-poster-image{
        width: 400px;
        border-radius: 50px ;
    }
}
@media (max-width:1033px){
    .show-heading{
        font-size: 4rem;
    }
     .genre-name{
         padding: 10px 20px;
        margin-bottom: 10px;
    }
    .show-desc{
        width: 73%;
    }
}
@media (max-width:890px) {
    .genres{
        width: 100%;
        justify-content: center;
    }
    .show-banner-details{
        grid-template-columns: 1fr;
        text-align: center;
    }
    .show-desc{
        margin: 0 auto;
    }
}
@media (max-width:530px){
    .show-poster-image{
        width: 300px;
        
    }
    .show-heading{
        font-size: 2.9rem;
    }
}
@media (max-width:380px){
    .show-poster-image{
        width: 250px;
        
    }
    .show-heading{
        font-size: 2rem;
    }
    .genres {
        padding: 10px;
    }
}