.footer{
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.footer-branding{
    padding-bottom: 20px;
}
.footer-menu__list{
    padding: 10px;
    width: 30px;
    height: 30px;
}
.footer p{
    padding-top: 20px;
    
}